import React from 'react';
import ReactDOM from 'react-dom';
import CookieConsent from "react-cookie-consent"
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from "history";
import Home from './components/home/Home';
import Footer from "./components/footer/Footer";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Dropzone from "./components/dropzone/DropZone";
import SearchForm from './components/search/Search';
import ResetPassword from './components/reset_password/ResetPassword';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const historyInstance = createBrowserHistory();

  return (
    <div className="App">
      <div className="">
        <Router history={historyInstance}>
        <Switch>
          {/*
          <Route path='/' component={() => { 
              window.location.href = 'https://svenskaleder.se/'; 
              return null;
          }}/>
          <Route path='/about' component={About} />
          <Route path='/contact' component={Contact} />
          <Route path='/upload' component={Dropzone} />
          <Route path='/search' component={SearchForm} />
          */}
          <Route path="/resetPassword/:token" component={ResetPassword} />
        </Switch>
      </Router>
    
    {/*
    <CookieConsent debug={true} 
    buttonText="I Accept"
    declineButtonText="Decline" 
    style={{ background: "rgba(31, 40, 34, 1)" }}
    hideOnAccept={true} 
    enableDeclineButton flipButtons>
      This website uses cookies to enhance the user experience.{" "}
    </CookieConsent>
    */}
    </div>
    </div>
  );
}

ReactDOM.render(
    <App />,
  document.querySelector("#root")
);
