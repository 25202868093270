import styled from "styled-components";

export const BodyContainer = styled.div`
@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400italic,500,600');
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  color: black;
}
* {
  box-sizing: border-box;
}
.border-2px-tahiti-gold {
  border: 2px solid rgba(234, 126, 0, 1);
}
.home {
  align-items: flex-start;
  background-color: white;
  background-image: url('https://anima-uploads.s3.amazonaws.com/projects/60550d4ea0727deb690378e0/releases/60551817354dbd5e3f27029a/img/jay-mantri-tfyi0qox08c-unsplash-2@1x.svg');
  display: flex;
  height: 1000px;
  justify-content: center;
  overflow: hidden;
  width: 1440px;
}
.home .group {
  height: 11px;
  left: 0px;
  position: absolute;
  top: 54px;
  width: 110px;
}
.home .group-1 {
  align-items: flex-start;
  display: flex;
  height: 6px;
  left: 1px;
  min-width: 45px;
  position: absolute;
  top: 79px;
}
.home .group-1114 {
  align-items: flex-start;
  display: flex;
  height: 40px;
  left: 634px;
  min-width: 186px;
  position: absolute;
  top: 464px;
}
.home .group-1114:hover {
  transform: translate(0, -5px);
  box-shadow: 0px 12px 5px -8px rgba(0, 0, 0, 0.2);
}
.home .group-1115 {
  align-items: flex-start;
  display: flex;
  height: 40px;
  left: 634px;
  min-width: 186px;
  position: absolute;
  top: 399px;
}
.home .group-1115:hover {
  transform: translate(0, -5px);
  box-shadow: 0px 12px 5px -8px rgba(0, 0, 0, 0.2);
}
.home .group-2 {
  align-items: flex-start;
  display: flex;
  height: 6px;
  left: 56px;
  min-width: 25px;
  position: absolute;
  top: 79px;
}
.home .group-3 {
  align-items: flex-start;
  display: flex;
  height: 6px;
  justify-content: flex-end;
  left: 92px;
  min-width: 18px;
  position: absolute;
  top: 79px;
}
.home .group-4 {
  height: 92px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 110px;
}
.home .group-644 {
  align-items: flex-start;
  display: flex;
  height: 91px;
  left: 23px;
  min-width: 110px;
  position: absolute;
  top: 79px;
}
.home .iconly-light-outline-search {
  height: 27px;
  left: 374px;
  position: absolute;
  top: 1394px;
  width: 26px;
}
.home .overlap-group {
  height: 2229px;
  margin-top: -35px;
  position: relative;
  width: 1442px;
}
.home .overlap-group1 {
  height: 92px;
  margin-top: -0.5px;
  position: relative;
  width: 110px;
}
.home .overlap-group1-1 {
  height: 30px;
  margin-left: -2px;
  margin-top: -2.19px;
  position: relative;
  width: 30px;
}
.home .overlap-group2 {
  height: 7px;
  margin-top: -0.46px;
  position: relative;
  width: 11px;
}
.home .overlap-group3 {
  height: 7px;
  margin-top: -0.49px;
  position: relative;
  width: 30px;
}
.home .overlap-group4 {
  height: 7px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 11px;
}
.home .overlap-group5 {
  height: 6px;
  left: 11px;
  position: absolute;
  top: 0px;
  width: 11px;
}
.home .overlap-group6 {
  height: 7px;
  margin-top: -0.49px;
  position: relative;
  width: 19px;
}
.home .overlap-group7 {
  height: 7px;
  margin-top: -0.49px;
  position: relative;
  width: 22px;
}
.home .overlap-group7-1 {
  -webkit-backdrop-filter: blur(35px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(35px) brightness(100%);
  background-color: rgba(234, 126, 0, 1);
  border-radius: 10px;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  min-width: 184px;
  padding: 0 60px;
}
.home .overlap-group8 {
  -webkit-backdrop-filter: blur(35px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(35px) brightness(100%);
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  display: flex;
  height: 40px;
  min-width: 184px;
  padding: 8px 60px;
}
.home .rectangle-31 {
  background-color: rgba(255, 255, 255, 0.35);
  height: 566px;
  left: 496px;
  position: absolute;
  top: 0px;
  width: 450px;
}
.home .search {
  letter-spacing: 0px;
  min-height: 20px;
}
.home .search-1 {
  align-items: flex-end;
  background-size: 100% 100%;
  display: flex;
  height: 21px;
  justify-content: flex-end;
  left: 22px;
  min-width: 21px;
  position: absolute;
  top: 13px;
}
.home .svenska-leder {
  height: 63px;
  left: 447px;
  object-fit: cover;
  position: absolute;
  top: 132px;
  width: 558px;
}
.home .text-1 {
  left: 534px;
  letter-spacing: 0px;
  line-height: 27.6px;
  position: absolute;
  top: 249px;
  width: 377px;
}
.home .text-2 {
  left: 204px;
  letter-spacing: 0px;
  position: absolute;
  top: 663px;
}
.home .upload {
  letter-spacing: 0px;
  min-height: 20px;
  min-width: 62px;
}
.home .vector {
  height: 6px;
  left: 5px;
  position: absolute;
  top: 0px;
  width: 6px;
}
.home .vector-1 {
  height: 7px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 6px;
}
.home .vector-10 {
  height: 6px;
  left: 6px;
  position: absolute;
  top: 0px;
  width: 5px;
}
.home .vector-11 {
  align-self: center;
  height: 6px;
  margin-top: 0px;
  width: 4px;
}
.home .vector-12 {
  height: 7px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 5px;
}
.home .vector-13 {
  height: 6px;
  left: 5px;
  position: absolute;
  top: 1px;
  width: 6px;
}
.home .vector-14 {
  height: 6px;
  left: 11px;
  position: absolute;
  top: 1px;
  width: 2px;
}
.home .vector-15 {
  height: 6px;
  left: 13px;
  position: absolute;
  top: 1px;
  width: 6px;
}
.home .vector-2 {
  height: 6px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 6px;
}
.home .vector-3 {
  align-self: center;
  height: 6px;
  margin-bottom: 0px;
  width: 5px;
}
.home .vector-4 {
  height: 6px;
  left: 6px;
  position: absolute;
  top: 0px;
  width: 6px;
}
.home .vector-5 {
  height: 6px;
  left: 11px;
  position: absolute;
  top: 0px;
  width: 2px;
}
.home .vector-6 {
  height: 6px;
  left: 13px;
  position: absolute;
  top: 0px;
  width: 6px;
}
.home .vector-7 {
  height: 7px;
  left: 19px;
  position: absolute;
  top: 0px;
  width: 6px;
}
.home .vector-8 {
  height: 7px;
  left: 25px;
  position: absolute;
  top: 0px;
  width: 5px;
}
.home .vector-9 {
  height: 6px;
  left: 6px;
  position: absolute;
  top: 1px;
  width: 5px;
}
.home .x-melani-profil {
  left: 602px;
  letter-spacing: 0px;
  position: absolute;
  top: 861px;
}
.montserrat-medium-white-24px {
  color: white;
  font-family: 'Montserrat', Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-white-32px {
  color: white;
  font-family: 'Montserrat', Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-normal-beryl-green-62px {
  color: rgba(215, 234, 199, 1);
  font-family: 'Montserrat', Helvetica;
  font-size: 62px;
  font-style: italic;
  font-weight: 400;
}
.montserrat-normal-black-16px {
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Montserrat', Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.montserrat-semi-bold-black-16px {
  color: rgba(0, 0, 0, 0.45);
  font-family: 'Montserrat', Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-tahiti-gold-16px {
  color: rgba(234, 126, 0, 1);
  font-family: 'Montserrat', Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-white-16px {
  color: white;
  font-family: 'Montserrat', Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-white-18px {
  color: white;
  font-family: 'Montserrat', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-white-32px {
  color: rgba(255, 255, 255, 0.87);
  font-family: 'Montserrat', Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
`;
