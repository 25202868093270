import styled from "styled-components";

export const BodyContainer = styled.div`
@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400italic,500,600');
* {
  box-sizing: border-box;
}
.footer-component {
  align-items: flex-start;
  background-color: rgba(31, 40, 34, 1);
  display: flex;
  height: 345px;
  justify-content: center;
  overflow: hidden;
  width: 1440px;
  bottom: 0px;
}
.footer-component .allmnna-villkor {
  left: 816px;
  letter-spacing: 0px;
  pointer-events: auto;
  position: absolute;
  top: 178px;
  transition: all 0.2s ease;
  width: 208px;
}
.footer-component .allmnna-villkor:hover {
  transform: scale(1.1);
}
.footer-component .kontakt {
  left: 674px;
  letter-spacing: 0px;
  pointer-events: auto;
  position: absolute;
  top: 178px;
  transition: all 0.2s ease;
  width: 115px;
}
.footer-component .kontakt:hover {
  transform: scale(1.1);
}
.footer-component .line-24 {
  height: 37px;
  left: 645px;
  position: absolute;
  top: 171px;
  width: 3px;
}
.footer-component .line-25 {
  height: 36px;
  left: 796px;
  position: absolute;
  top: 175px;
  width: 3px;
}
.footer-component .om-oss {
  left: 524px;
  letter-spacing: 0px;
  pointer-events: auto;
  position: absolute;
  top: 178px;
  transition: all 0.2s ease;
  width: 114px;
}
.footer-component .om-oss:hover {
  transform: scale(1.1);
}
.footer-component .overlap-group {
  height: 344px;
  position: relative;
  width: 1460px;
}
.footer-component .rectangle-419 {
  background-color: rgba(31, 40, 34, 1);
  height: 343px;
  position:absolute;
  left:0;
  bottom:0;
  right:0;
  width: 1440px;
}
.footer-component .text-1 {
  left: 386px;
  letter-spacing: 0px;
  text-align: center;
  position: absolute;
  top: 94px;
  width: 683px;
}
.footer-component .vector-544 {
  height: 286px;
  left: 0px;
  position: absolute;
  top: 3px;
  width: 443px;
}
.footer-component .vector-545 {
  height: 204px;
  left: 1116px;
  position: absolute;
  top: 7px;
  width: 344px;
}
.footer-component .vector-546 {
  height: 156px;
  left: 0px;
  position: absolute;
  top: 2px;
  width: 92px;
}
.footer-component .vector-547 {
  height: 334px;
  left: 1105px;
  position: absolute;
  top: 9px;
  width: 354px;
}
.footer-component .vector-549 {
  height: 265px;
  left: 132px;
  position: absolute;
  top: 2px;
  width: 769px;
}
.footer-component .vector-550 {
  height: 193px;
  left: 514px;
  position: absolute;
  top: 116px;
  width: 810px;
}
.footer-component .vector-551 {
  height: 216px;
  left: 17px;
  position: absolute;
  top: 115px;
  width: 424px;
}
.montserrat-medium-white-24px {
  color: white;
  font-family: 'Montserrat-Medium', Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}
.montserrat-medium-white-32px {
  color: white;
  font-family: 'Montserrat-Medium', Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
}
`;