import styled from "styled-components";

export const BodyContainer = styled.div`
position: absolute;
width: 753px;
height: 1165px;
left: 0px;
top: 0px;

background: url(../assets/images/rapa-valley.jpeg);
background-blend-mode: darken;
`;

export const SearchFilters = styled.div`
`;