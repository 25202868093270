import styled from "styled-components";

export const BodyContainer = styled.div`
    .main-content {
        align-items: flex-start;
        background-color: white;
        background-image: url('https://anima-uploads.s3.amazonaws.com/projects/60550d4ea0727deb690378e0/releases/60551817354dbd5e3f27029a/img/jay-mantri-tfyi0qox08c-unsplash-2@1x.svg');
        display: flex;
        width: 1440px;
        height: 1000px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .map-container {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 300px;
        bottom: 0px;
    }
     
    .sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #ffffff;
    padding: 6px 12px;
    font: 15px/24px monospace;
    z-index: 1000;
    position: absolute;
    left: 300px;
    margin: 12px;
    border-radius: 4px;
    }
`;