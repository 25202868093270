import React, { Component } from 'react';
import axios from 'axios';
import { baseUrlSvenska } from "../common/baseUrl"
import { Button, Container, Row, InputGroup, FormControl } from 'react-bootstrap';

var formData = new FormData()

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            new_password: '',
            confirm_password: '',
            token: this.props.match.params.token,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    };

    resetForm() {
        this.setState({
            email: '',
            new_password: '',
            confirm_password: ''
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.validateForm())
            return;
        console.log(this.state)

        formData.append("email", this.state.email)
        formData.append("new_password", this.state.new_password)
        formData.append("confirm_password", this.state.confirm_password)
        formData.append("token", this.props.match.params.token)

        axios({
            method: 'post',
            url: `${baseUrlSvenska}/api/v1/auth/reset_password_form`,
            data: formData,
        }).then(response => {
            console.log(response);
            alert("Password reset successfully")
            this.resetForm();
        }).catch(error => {
            alert(error.message)
            console.log(error)
        });
    };

    validateForm() {
        let regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!(regexEmail.test(this.state.email))) {
            alert("Email not valid!")
            return false;
        }
        if (this.state.new_password !== this.state.confirm_password) {
            alert("Password doesn't match!")
            return false;
        }
        return true;
    }

    render() {
        return (
            <div>
                <Container>
                    <Row className="row justify-content-center m-3">
                        <h1>Återställ lösenord</h1>
                    </Row>
                    <Row className="justify-content-md-center">
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    E-post
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                isInvalid={!this.state.email}
                                aria-label="Email"
                                id="email" name="email" type="email" onChange={this.handleChange} value={this.state.email}
                            />

                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Nytt lösenord
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Email"
                                id="new_password" name="new_password" type="password" onChange={this.handleChange} value={this.state.new_password}
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    Bekräfta lösenord
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                aria-label="Email"
                                id="confirm_password" name="confirm_password" type="password" onChange={this.handleChange} value={this.state.confirm_password}
                            />
                        </InputGroup>
                        <InputGroup.Append className="m-3">
                            <Button size="lg" onClick={this.handleSubmit} variant="outline-success" block>Återställa</Button>
                        </InputGroup.Append>

                    </Row>
                </Container>
            </div>
        );
    }
}
export default ResetPassword;
