import React, { useState} from "react";
import { BodyContainer } from "./SearchStyle";
import MapboxGLMap from "../map/Map";
import axios from "axios";

function SearchForm({ onSearch }) {
  const [query, setQuery] = useState({ text: "" });

  function handleChange(event) {
    const newQuery = Object.freeze({ text: event.target.value });
    setQuery(newQuery);
  }

  function search() {
    const newQuery = Object.freeze({ text: query.text });
    if (onSearch) onSearch(newQuery);
  }

  axios.post('/web-search', {
    "query": "SL1_5 Brotorpet-Vesslarp.gpx"
  })
  .then((response) => {
    console.log(response);
  }, (error) => {
    console.log(error);
  });

  return (
    <div>
      <BodyContainer>
      <input type="text" onChange={handleChange} />
      <button onClick={search} type="button">
        Search
      </button>
    </BodyContainer>
    <MapboxGLMap/>
    </div>
  );
}


export default SearchForm;