import styled from "styled-components";

export const BodyContainer = styled.div`
@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400italic,500,600,700');
@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
* {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: black;
}

.main-content {
  align-items: flex-start;
  background-color: white;
  background-image: url('https://anima-uploads.s3.amazonaws.com/projects/60550d4ea0727deb690378e0/releases/60551817354dbd5e3f27029a/img/jay-mantri-tfyi0qox08c-unsplash-2@1x.svg');
  display: flex;
  width: 1440px;
  height: 1000px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-input {
  display: none;
}

.file-upload-btn {
  color: black;
  text-transform: uppercase;
  outline: none;
  background-color: black;
  font-weight: bold;
  padding: 8px 15px;
  margin-bottom: 5px;
}

.container {
  transform: translateY(-100%);
}

.container p {
  color: red;
  text-align: center;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  position: relative;
  top: 300px;
  width: 1000px;
  height: 300px;
  border: 4px dashed black;
}

.drop-message {
  text-align: center;
  color: black;
  font-family: Arial;
  font-size: 20px;
}

.file-progressBar {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  display: inline-block;
  margin: 0px 10px 5px 5px;
  vertical-align: top;
  flex: 6 !important;
  margin-left: 50px;
  box-sizing: content-box;
  width: calc(100% - 50px);
}

.file-progressBar div {
  height: auto;
  color: #fff;
  text-align: right;
  line-height: 15px;
  width: 0;
  background-color: #4caf50;
  border-radius: 3px;
  font-size: 13px;
}

.file-display-container {
  position: fixed;
  width: 805px;
}

.file-status-bar {
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 50px;
  height: 50px;
}

.file-status-bar > div {
  overflow: hidden;
}

.file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: black;
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: black;
}

.file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}

.file-error-message {
  color: red;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  background: no-repeat center center;
  background-size: 100%;
  position: absolute;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.file-remove {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}

.modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.modal .overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.modal .modal-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.modal-image-text {
  position: absolute;
  color: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #013220;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.upload-modal {
  z-index: 999;
  display: none;
  overflow: hidden;
}

.upload-modal .overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.66);
  position: absolute;
  top: 0;
  left: 0;
}

.progress-container {
  background: #013220;
  width: 500px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.progress-container span {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  font-size: 20px;
}

.progress {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #efefef;
  height: 20px;
  border-radius: 5px;
}

.progress-bar {
  position: absolute;
  background-color: black;
  height: 20px;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.error {
  color: red;
}
.montserrat-semi-bold-black-16px {
  color: black;
  font-family: 'Montserrat', Helvetica;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-semi-bold-black-32px {
  color: #013220;
  font-family: 'Montserrat', Helvetica;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.montserrat-bold-dark-green-68px {
  color: #013220;
  font-family: 'Montserrat', Helvetica;
  font-size: 68px;
  font-style: normal;
  font-weight: 700;
}
.text-1 {
  letter-spacing: 0px;
  min-height: 180px;
  min-width: 805px;
}
.text-2 {
  letter-spacing: 0px;
  min-height: 100px;
  min-width: 805px;
}
`;

export const DropZoneImage = styled.div`
    width: 70px;
    height: 70px;
    background-size: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 30px;
    background-image: url(${props => props.img});
`;