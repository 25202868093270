import React, { Component } from "react";
import {BodyContainer} from "./MapStyle";
import mapboxgl from "mapbox-gl";
import {MapBoxToken} from "../common/Credentials";

mapboxgl.accessToken = MapBoxToken;

class MapboxGLMap extends Component {
  _isMounted = false;
  constructor(props) {
    super(props); 
    this.state={
        lng:11.974560,
        lat: 57.708870,
        zoom: 10,
    }
    this.mapContainer = React.createRef();
  }

  componentDidMount(){
    this._isMounted = true;
    this.loadMap();
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  loadMap(){
    const { lng, lat, zoom } = this.state;
    const map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: 'mapbox://styles/mapbox/outdoors-v10',
      center: [lng, lat],
      zoom: zoom
    });
    map.on('move', () => {
      this.setState({
      lng: map.getCenter().lng.toFixed(4),
      lat: map.getCenter().lat.toFixed(4),
      zoom: map.getZoom().toFixed(2)
      });
    
    });
    }

  render(){
    const { lng, lat, zoom } = this.state;
    return (
          <BodyContainer>
            <div className="sidebar">
            Longitud: {lng} | Latitud: {lat} | Zoom: {zoom}
            </div>
            <div ref={this.mapContainer} className="map-container" />
            <div className="main-content">
            </div>
          </BodyContainer>
    );
  }
};

export default MapboxGLMap;